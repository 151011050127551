import React, {Component} from 'react';
import './TestHarness.scss';
import ComboCode from '@gp-cart/combo-code-test-harness';

export default class TestHarness extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Token: "",
            OrderId:"",
            Environment: "",
            openOrder: false
        };
    }

    handleChange = event => {
        this.setState({[event.target.id] : event.target.value});
    };

    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            openOrder: !this.state.openOrder,
        });
    }

    render() {
        const version = '1.7.8'
        return (
            <div>
                {
                    !this.state.openOrder ?
                        <div id="test-harness-container">
                            <div className="header">
                                <p> Test-harness version {version} </p>
                            </div>
                            <form className="text-harness-form" onSubmit={this.handleSubmit.bind(this)}>
                                <div className='form-group'>
                                    <label htmlFor='OrderId'>Order Id</label>
                                    <input
                                        className={"form-control"}
                                        id='OrderId'
                                        placeholder='Enter Order Id'
                                        value={this.state.OrderId}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='Token'>User Token</label>
                                    <input
                                        className={"form-control"}
                                        id='Token'
                                        placeholder='Enter Token'
                                        value={this.state.Token}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='Environment'>Environment</label>
                                    <input
                                        className={"form-control"}
                                        id='Environment'
                                        placeholder='Enter Testing Environment'
                                        value={this.state.Environment}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <input type="submit" name="submit" value="Proceed" onClick={this.handleSubmit}/>
                            </form>
                        </div>
                        :
                        <ComboCode orderId={this.state.OrderId} userToken={this.state.Token}  environment={this.state.Environment}  successCallback={successCallback} failureCallback={failureCallback} cancelCallback={cancelCallback}/>
                }
            </div>
        );
    }
}

const successCallback = (data) => {
    alert(JSON.stringify(data));
};

const failureCallback = () => {
    alert("failure");
};

const cancelCallback=()=>{
    alert("cancel");
}
